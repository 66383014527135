import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'

const GalleryPage = ({ data }) => {
  return (
    <Layout pageTitle="Gallery" showPageBanner={true}>
      <div className="container mb-16">
        <div className="masonry-1 md:masonry-2 lg:masonry-3">
          {
            data.allFile.nodes.map((node) => {
              const image = getImage(node)
              return (
              <div className="mb-4" key={node.id}>
                <GatsbyImage image={image} alt="" />
              </div>)
            })
          }
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query {
  allFile(sort: { fields: name, order: DESC }, filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "gallery/photos"}}) {
    nodes {
      id
      name
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO]
        )
      }
    }
  }
}
`

export default GalleryPage